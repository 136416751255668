import { IUpdateUserDTO, IUserDTO, IUserList } from '../../data/dto/user-dto';
import { buildTableFiltarablePageableRequest } from '../../data/mappers/build-page-request';
import { FreeTextSearch, IConditions, SortingRequest } from '../../data/models/search-models';
import { useConfigProvider } from '../../providers/config/useConfigProvider';
import useCustomAxios from '../interceptors/useCustomAxios';

interface IUseAxiosUsers {
  getList: (
    offset: number,
    limit: number,
    conditions: IConditions[],
    sorting?: SortingRequest,
    freeSearch?: FreeTextSearch
  ) => Promise<IUserList>;
  getUser: (id: string) => Promise<IUserDTO>;
  getUserByUid: (uid: string) => Promise<IUserDTO>;
  addUser: (user: IUserDTO) => Promise<string>;
  updateUser: (user: IUpdateUserDTO) => Promise<string>;
  deleteUser: (id: string[]) => Promise<void>;
  activateUser(id: string[]): Promise<void>;
}

interface IBodyDeleteUser {
  ids: string[];
}

export default function useAxiosUsers(): IUseAxiosUsers {
  const { firebase } = useConfigProvider();
  const { axios } = useCustomAxios();
  const usersUrl = `${firebase.baseUrl}/users`;

  /**
   *
   * @param offset - index of element
   * @param limit - number of element to return
   * @param conditions - vector of conditions of filters
   * @returns a promise of Groups
   */
  const getList = async (
    offset: number,
    limit: number,
    conditions: IConditions[],
    sorting?: SortingRequest,
    freeSearch?: FreeTextSearch
  ): Promise<IUserList> => {
    const body = buildTableFiltarablePageableRequest(offset, limit, conditions, sorting, freeSearch);
    const res: IUserList = await (await axios.post(`${usersUrl}/list`, body)).data;
    return res;
  };

  const getUser = async (id: string): Promise<IUserDTO> => {
    const response = await axios.get(`${usersUrl}/${id}`);
    return response.data;
  };
  const getUserByUid = async (uid: string): Promise<IUserDTO> => {
    const response = await axios.get(`${usersUrl}/${uid}`);
    return response.data;
  };

  const addUser = async (userToAdd: IUserDTO): Promise<string> => {
    const response = await axios.post(`${usersUrl}/create`, userToAdd);
    return response.data;
  };

  const updateUser = async (userToUpdate: IUpdateUserDTO): Promise<string> => {
    const response = await axios.put(`${usersUrl}/update`, userToUpdate);
    return response.data;
  };

  const deleteUser = async (id: string[]): Promise<void> => {
    const body: IBodyDeleteUser = {
      ids: id
    };
    const response = await axios.post(`${usersUrl}/delete`, body);
    return response.data;
  };

  const activateUser = async (id: string[]): Promise<void> => {
    const body: IBodyDeleteUser = {
      ids: id
    };
    const response = await axios.post(`${usersUrl}/activate`, body);
    return response.data;
  };

  return { getList, getUser, addUser, updateUser, deleteUser, getUserByUid, activateUser };
}
